import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { PageContext } from '../../context/ApplicationState'

const ProfilesLayout = styled.div`

  /* max-width: ${props => props.theme.containerWidths.large}; */
  max-width: 984px;
  margin-left: auto;
  margin-right: auto;

  & + & {
    margin-top: ${props => props.theme.section.topSm};
  }

  .top-artist-card {
    color: white;

    * {
      color: inherit;
    }

    figure {
      position: relative;

      figcaption {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 12px 0px 6px;
        text-align: center;
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 0)
        );
      }
    }

    .name {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0px;
    }

    .location {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .top-artist-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 3px;
  }

  &[data-grid-prominence='1' i] {
    max-width: 772px;

    .top-artist-grid {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    & + & {
      margin-top: ${props => props.theme.section.topLg};
    }

    .top-artist-card {
      .name {
        font-size: 15px;
        line-height: 18px;
      }
    }

    &[data-grid-prominence='1' i],
    &[data-grid-prominence='2' i],
    &[data-grid-prominence='3' i] {
      figure {
        figcaption {
          padding: 20px 8px 12px;
        }
      }

      .name {
        margin-bottom: 2px;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .top-artist-grid {
      grid-template-columns: repeat(5, minmax(0, 1fr));
      grid-gap: 12px;
    }

    &[data-grid-prominence='2' i] {
      .top-artist-grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    &[data-grid-prominence='3' i] {
      .top-artist-grid {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }
`

const TopArtists = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTopArtistProfile(
        filter: { rank: { ne: "Seint's Club" } }
      )
       {
        edges {
          node {
            id
            name
            slug
            ...TopArtistProfileImageSettings
            rank
            location
          }
        }
      }
    }
  `)

  const profilesFromContentful = data.allContentfulTopArtistProfile.edges

  const sortProfiles = profiles => {
    // Sort profiles alphabetically by artist's name
    profiles.sort((a, b) => {
      if (a.node.name && b.node.name) {
        const nameA = a.node.name.toUpperCase()
        const nameB = b.node.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    })

    // Group artist profiles by rank
    const groupedProfiles = {}
    for (const profile of profiles) {
      if (profile.node.rank) {
        const key = `${profile.node.rank
          .split(/[_\s]/)
          .join('_')
          .toUpperCase()}`
        if (!groupedProfiles[key]) {
          groupedProfiles[key] = []
        }
        groupedProfiles[key].push(profile)
      }
    }
    const ranksAndProfiles = []
    for (const profiles of Object.values(groupedProfiles)) {
      const rankAndProfiles = {
        title: profiles[0].node.rank,
        profiles,
      }
      ranksAndProfiles.push(rankAndProfiles)
    }
    // Sort ranks top to bottom
    ranksAndProfiles.sort((a, b) => {
      const nameA = a.title.toUpperCase()
      const nameB = b.title.toUpperCase()
      if (nameA === 'ARTIST X') {
        return -1
      }
      if (nameB === 'ARTIST X') {
        return 1
      }
      if (nameA === 'ARTIST IX') {
        return -1
      }
      if (nameB === 'ARTIST IX') {
        return 1
      }
      if (nameA === 'ARTIST VIII') {
        return -1
      }
      if (nameB === 'ARTIST VIII') {
        return 1
      }
      if (nameA === 'ARTIST VII') {
        return -1
      }
      if (nameB === 'ARTIST VII') {
        return 1
      }
    })
    return ranksAndProfiles
  }

  const profileGroups = sortProfiles(profilesFromContentful)

  const { pageState, setPageState } = useContext(PageContext)

  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: true,
      hasHeroSection: false,
    }))
  }, [])

  useEffect(() => {
    document.querySelector('body').classList.add('has-light-header')
    return () =>
      document.querySelector('body').classList.remove('has-light-header')
  }, [])

  return (
    <div className="top-artists-container text-center">
      {profileGroups.map((rank, i) => (
        <ProfilesLayout key={i} data-grid-prominence={i < 2 ? 2 : i + 1}>
          <h2 className="headline--small">{rank.title}</h2>
          <div className="top-artist-grid">
            {rank.profiles.map(({ node: profile }) => (
              <Link
                key={profile.id}
                className="top-artist-card"
                to={`/recognition/legacy-leaders/${profile.slug}`}
              >
                <figure>
                  <Img
                    alt={`${profile.name}`}
                    fluid={profile.heroImage.fluid}
                  />
                  <figcaption>
                    <h3 className="name">{profile.name}</h3>
                    <p className="location">{profile.location}</p>
                  </figcaption>
                </figure>
              </Link>
            ))}
          </div>
        </ProfilesLayout>
      ))}
    </div>
  )
}

export default TopArtists
